<template>

<div class="wide-employment">
    <img :src="image" :alt="`${name} Logo`"/>
    <div class="description">
        <h2 v-if="link === undefined">{{ name }}</h2>
        <h2 v-if="link !== undefined"><a :href="link" target="_blank">{{ name }}</a></h2>
        <h3>{{ position }} | {{ date }}</h3>
        <div class="description-text">
            <slot></slot>
        </div>
    </div>
</div>
<div class="narrow-employment">
    <div class="heading">
        <img :src="image" :alt="`${name} Logo`"/>
        <div class="title">
            <h2 v-if="link === undefined">{{ name }}</h2>
            <h2 v-if="link !== undefined"><a :href="link" target="_blank">{{ name }}</a></h2>
            <h3>{{ position }}</h3>
            <h3>{{ date }}</h3>
        </div>
    </div>
    <div class="description-text">
        <slot></slot>
    </div>
</div>

</template>

<script>
export default {
  props: ['name', 'position', 'date', 'image', 'link'],
}

</script>
<style>

.wide-employment {
    display: flex;
    margin-bottom: 5vh;
    gap: 5mm;
}


.wide-employment img {
    width: min(16vmin, 3cm);
    height: min(16vmin, 3cm);
    /* border-radius: 16vmin; */
    object-fit: cover;
    /* padding: 2vmin; */
    
}

.wide-employment p {
    margin: 0 0 0.5em 0;
}

.narrow-employment .heading {
    display: flex;
    gap: 15px;
}

.narrow-employment .heading .title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.narrow-employment img {
    height: 20vmin;
}

.narrow-employment h1, h2, h3 {
    margin: 0;
    padding: 0;
}

.narrow-employment .description-text {
    margin: 10px 0 5vh 0;
}

@media (min-width: 500px) {
    .narrow-employment {
        display: none;
    }
}
@media (max-width: 499px) {
    .wide-employment {
        display: none;
    }
}

</style>