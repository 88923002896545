<template>

<div class="personal">
    <a :href="link">
    <img :src="image" v-if="img !== null" :alt="alt"/>
    <h2>{{ name }}</h2>
    </a>
    <slot name="video"></slot>
    <div class="description-text">
        <slot name="text"></slot>
    </div>
</div>

</template>

<script>
export default {
    props: ['name', 'position', 'image', 'link', 'alt'],
}

</script>
<style>

.personal {
    display: flex;
    flex-direction: column;
    /* min-width: max(20vw, 300px); */
    width: clamp(min(300px, 100%), 40%, min(100%, 1800px));
    flex-grow: 1;
    /* background-color: red; */
}

.personal .description-text {
    flex-grow: 1;
    margin-bottom: 20px;
}

.personal iframe {
    margin-bottom: 20px;
}

.personal img {
    /* height: 30vh; */
    margin-bottom: 20px;
    width: 100%;
    object-fit: contain;
    object-position: center;
    cursor: pointer;
    /* border-radius: 16vmin; */
    /* object-fit: cover; */
}

.personal img:hover {
    opacity: 70%;
}

.personal h2 {
    margin-bottom: 10px;
}

</style>