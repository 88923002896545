<template>
<div class="section"><div class="sectionbind">
    <h1>Droppie</h1>
    <div class="droppie-page">
    <div class="droppie-text">
        <p>The idea behind Droppie was simple: Users can create pictures and "drop" them at their current location. Other users can then "pick up" the pictures by walking over them. Once you picked up a picture, you could respond to it by sending the owner a message, thus starting a picture conversation.</p>
        <h2>Project Timeline</h2>
        <p>The project began in 2014 and I joined it in June of that year. By December of the same year we had finished the first version, found an investor and launched in Slovakia. Motivated by the initial success, we quickly gathered as much user feedback as we could and began working on the second version, adding a map and optimising the server.</p>
        <p>We rolled out the second version in April 2015 and began preparing for a launch at the United States, which took place in August 2015. The peak of our project was about 12 000 users and it slowly began to get increasingly difficult to grow beyond that number. We realised people were not comfortable with us being aware of their location (a fairly necessary requirement for a location-based app), which greatly held back our expansion. After thorough analysis of the growth by us and the investors, it was decided that Droppie will be scrapped in December 2015, which concluded our adventure.</p>
        <h2>Tech</h2>
        <p>I was in charge of the Backend of the project. The server was written in Python (Flask microframework) and deployed on Google App Engine. We used a MySQL database (Cloud SQL), Google memcache and Datastore technologies along with some evil-looking mathematics that I wrote.</p>
    </div>
    <div class="carousel">
        <vueper-slides :slide-ratio="500 / 281">
            <vueper-slide v-for="(slide, i) in slides" :key="i" :image="slide.image" />
        </vueper-slides>
    </div>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/dpZCI4KJQ7o" frameborder="0" allowfullscreen></iframe>
</div>
</div></div>
</template>
<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  components: { VueperSlides, VueperSlide },
  mounted() {
    document.title = "RoboCup";
  },
  data: () => ({
        slides: [
            {image: "/img/droppie/title.jpg"},
            {image: "/img/droppie/map.jpg"},
            {image: "/img/droppie/pickups.jpg"},
            {image: "/img/droppie/replies.jpg"},
            {image: "/img/droppie/actions.jpg"},
        ]
    })
}

</script>
<style>

.droppie-page {
    display: flex;
    flex-grow: 1;
    gap: 20px;
    flex-wrap: wrap;
}

.carousel {
    min-width: 300px;
    max-width: 300px;
}

.droppie-page iframe {
}

.droppie-page .droppie-text {
    flex-grow: 1;
    width: clamp(300px, 70%, 1000px);
    /* min-width: 300px; */
}

.vueperslides__arrow {
    color: black;
}

</style>