<template>

<div class="skill">
    <div class="skill-heading">
        <img :src="image" :alt="`${name} Logo`"/>
        <div>{{ name }}</div>
    </div>
    <div class="skill-details">
        <slot></slot>
    </div>
</div>

</template>
<script>
export default {
    props: ['image', 'name'],
}
</script>
<style>

.skill {
    display: flex;
    flex-direction: column;
    width: 25%;
    flex-grow: 1;
    min-width: 300px;
}

.skill-heading {
    display: flex;
    line-height: 1cm;
    font-size: 0.8cm;
    color: var(--theme-text);
}

.skill-heading div {
    padding-left: 10px;
}

.skill img {
    width: 1cm;
    height: 1cm;
}

.skill-details {
    color: var(--theme-darkest);
    margin-top: 10px;
    text-align: justify;
}

</style>
