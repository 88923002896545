<template>
  <nav ref="wide-nav" id="wide-nav">
    <a href="/#home">Home</a>
    <a href="/#employment">Employment</a>
    <a href="/#techstack">Tech Stack</a>
    <a href="/#personal">Personal</a>
    <a href="/#talks">Talks</a>
    <a href="/#publications">Publications & Patents</a>
    <a href="/#regulatory">Regulatory</a>
  </nav>
  <nav ref="narrow-nav" id="narrow-nav" @click="toggleAnchors">
    <div class="three-bars">
      <div></div>
      <div></div>
      <div></div>
    </div>
    <a v-if="displayAnchors" @click="hideAnchors" href="/#home">Home</a>
    <a v-if="displayAnchors" @click="hideAnchors" href="/#employment">Employment</a>
    <a v-if="displayAnchors" @click="hideAnchors" href="/#techstack">Tech Stack</a>
    <a v-if="displayAnchors" @click="hideAnchors" href="/#personal">Personal</a>
    <a v-if="displayAnchors" @click="hideAnchors" href="/#talks">Talks</a>
    <a v-if="displayAnchors" @click="hideAnchors" href="/#publications">Publications & Patents</a>
    <a v-if="displayAnchors" @click="hideAnchors" href="/#regulatory">Regulatory</a>
  </nav>
  <div v-if="displayAnchors" class="overall" @click="hideAnchors"></div>
  <router-view/>
  <div class="copyright">{{ copyright }}</div>
</template>
<script>
export default {
  data() {
    return {
      displayAnchors: false
    }
  },
  methods: {
    toggleAnchors(e) {
      this.displayAnchors = !this.displayAnchors;
      e.stopPropagation();
    },
    hideAnchors(e) {
      this.displayAnchors = false;
      e.stopPropagation();
    },
  },
  computed: {
    copyright() {
      return `Copyright © Simon Rovder ${new Date().getFullYear()}`
    }
  },
  // mounted() {
  //   document.body.addEventListener("click", (e) => {
  //     this.displayAnchors = false;
  //   })
  // },
  // unmounted() {
  //   document.body.removeEventListener("click");
  // }
}
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--theme-text);
  margin: 0;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  line-height: 1.41;
  font-size: 14px;
}

body {
  margin: 0;
  margin-top: 100px;
}

a {
  color: var(--theme-dark);
}

nav {
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  background-color: var(--theme-darkest);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
}

nav a {
  display: flex;
  align-items: center;
  font-weight: bold;
  white-space: nowrap;
  color: var(--theme-brightest);
  text-decoration: none;
  line-height: 3em;
}

#wide-nav {
  gap: 30px;
  padding-left: 30px;
}

#narrow-nav {
  flex-direction: column;
}

#narrow-nav a {
  padding-left: 2mm;
}

.overall {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: white;
  opacity: 55%;
}

nav a.router-link-exact-active {
  color: var(--theme-bright);
}

h1, h2, h3, h4 {
  color: var(--theme-text);
}

:target {
  display: block;
  position: relative;
  top: -80px;
  visibility: hidden;
}

:root {
  --theme-brightest: #93B1A6;
  --theme-darkest: #040D12;
  --theme-dark: #183D3D;
  --theme-text: #333;
  --theme-link: rgb(63, 129, 104);
  --theme-bright: #5C8374;
  --theme-bright-background: #f0f0f0;

  --mobile-cutoff: 500px;
}

.three-bars {
  width: 6mm;
  height: 6mm;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 2mm;
}

.three-bars div {
  height: 20%;
  border-radius: 1cm;
  background-color: var(--theme-brightest);
  width: 100%;
}

@media (min-width: 761px) {
  #narrow-nav {
    display: none;
  }
}


@media (max-width: 760px) {
  #wide-nav {
    display: none;
  }
}

.copyright {
  color: #666;
  text-align: center;
  padding: 20px;
}

</style>
