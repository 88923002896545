<template>
<div class="cert">
    <div class="isolabel"><p>ISO</p><p>{{ iso }}</p></div>
    <div class="isosubtitle">{{ sub }}</div>
    <div><slot></slot></div>
</div>

</template>

<script>
export default {
  props: ['iso', 'sub'],
}

</script>
<style>

.cert {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5vmin 1vw 5vmin 1vw;
    width: 30vw;
    flex-grow: 1;
}

.isolabel {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    box-shadow: 0 0 10px 0px #aaaaaa;
    display: flex;
    align-items: center;
    border: 2px #c7c7c7 solid;
    justify-content: center;
    font-weight: bold;
    display: flex;
    flex-direction: column;
}

.isolabel p {
    margin: 0;
}

.isolabel p:nth-child(2) {
    margin-top: 5px;
    font-size: 1.3em;
}

.isosubtitle {
    margin-top: 20px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
}
.octagon:before {
    position: absolute;
    /* There needs to be a negative value here to cancel
     * out the width of the border. It's currently -3px,
     * but if the border were 5px, then it'd be -5px.
     */
    top: -3px; right: -3px; bottom: -3px; left: -3px;
    transform: rotate(45deg);
    content: '';
    border: inherit;
}

</style>