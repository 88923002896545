<template>
    <div class="intro-div">
        <img class="intro-pic" src="/img/profile_pic_small.png" alt="Simon Rovder"/>
        <div class="text-intro">
            <h1>Simon Rovder</h1>
            <h2>CTO and Co-Founder at <a href="https://powerfulmedical.com/">Powerful Medical</a></h2>
            <p>Hey, my name is Simon, welcome to my portfolio / homepage. This page is mainly here to give you an idea of what I do, so lets get on that.</p>
            <p>My background is heavily engineering-focused and I started my journey in Backend development. During my professional career I have worked with AI, cloud technologies, frontend & backend both, the blockchain, dealt with regulators, published some research and even patented some technologies.</p>
            <p>Today I am the CTO at Powerful Medical, a startup I co-founded in 2018. Powerful Medical focuses on bringing state-of-the-art <a target="_blank" href="https://www.powerfulmedical.com/pmcardio">AI to the frontline of the medical field</a>, and recently even <a target="_blank" href="https://www.powerfulmedical.com/pmcardio-omi">pushing the boundary of what was thought possible</a>.</p>
            <b>The University of Edinburgh - Master of Information Technology</b>
            <p style="display: flex; gap: 3mm">
                <a target="_blank" href="https://www.facebook.com/simon.rovder"><img class="social-icon" src="/img/icons/facebook.png" alt="Simon Rovder's Facebook"/></a>
                <a target="_blank" href="https://www.linkedin.com/in/simon-rovder-b9091696/"><img class="social-icon" src="/img/icons/linkedin.png" alt="Simon Rovder's Linkedin"/></a>
                <a target="_blank" href="https://twitter.com/SimonRovder"><img class="social-icon" src="/img/icons/twitter.png" alt="Simon Rovder's Twitter"/></a>
            </p>
        </div>
    </div>
</template>

<script>

</script>
<style>

.social-icon {
    width: 7mm;
    height: 7mm;

}

.intro-pic {
  align-self: center;
  box-shadow: 0 0 10px 0px #888888;
  border-radius: 15px;
}

@media (min-width: 500px) {
    .intro-pic {
        float: left; clear: both; overflow: auto;
        margin-right: 20px;
        margin-bottom: 20px;
        width: clamp(max(20%, 5cm), 30%, 30%);
    }

    .text-intro h1 {
        text-align: left !important;
    }
}
@media (max-width: 499px) {
    .intro-div {
        display: flex;
        flex-direction: column;
    }
    .intro-pic {
        height: 30vh;
        margin-bottom: 5vmin;
    }
    .intro-div {
        /* text-align: center; */
    }
}

.text-intro {
    padding-bottom: 30px;
}

</style>