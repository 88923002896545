<template>

<div class="section"><div class="sectionbind"><span id="home"></span>
<Intro/>
</div></div>
<div id="sections">
  
  <div class="section"><div class="sectionbind"><span id="employment"></span>
    <h1>Employment</h1>
    <Employment image="/img/powerfulmedical.jpeg" name="Powerful Medical" position="CTO & Co-Founder" date="2019 - Present" link="https://powerfulmedical.com">
      <slot>
        <p>In 2018 we set out with the goal of bringing state-of-the-art AI to the field of cardiology. Today, our startup, Powerful Medical, is a team of 50 strong, we are an EU MDR certified medical device manufacturer, and our flagship product, <a href="https://www.powerfulmedical.com/pmcardio">PMcardio</a>, is out there saving lives.</p>
        <p>In the early months of the startup we were only two engineers, and I was laying the groundwork for our tech: I created initial prototypes of our product, wrote the foundations of our cloud infrastructure, and created the first AI pipelines (some of which we have now patented).</p>
        <p>As the company grew, my day-to-day as CTO evolved. These days I lead the department in a managerial and tech-lead capacity - we are making massive leaps forward in AI and tech on a monthly basis, and I am here to make ensure our R&D efforts work smoothly, the team grows at a healthy pace, and the direction of our technological progress is aligned with our business needs.</p>
      </slot>
    </Employment>
    <Employment image="/img/bethereum.png" name="Bethereum" position="Lead Backend Engineer" date="2018 - 2019">
      <slot>
        <p>Bethereum is a startup that has been moving betting onto the blockchain since 2017. I joined in early 2018 and built the infrastructure required to run a massive bounty program (a crypto marketing phenomenon). Later I lead the Backend development for their platform and took over the role as lead Blockchain engineer as well. At its peak the platform had about 200 000 daily active users.</p>
      </slot>
    </Employment>
    <Employment image="/img/microsoft.png" name="Microsoft" position="Backend Engineering Intern" date="Summer 2017">
      <slot>
        <p>In the Summer of 2017 I did a 3 month internship in the Office Marketplace Experience (OMEX) team at Microsoft in Dublin. I worked on the C# server side of a tool that allows companies to check for possible VBA compatibility issues that may arrise from upgrading to the newest version of Office. I was offered a full-time position at Microsoft after the internship but decided to pursue a startup life instead - I wasn't done with the startup wilderness just yet.</p>
      </slot>
    </Employment>
    <Employment image="/img/droppie.webp" name="Droppie" position="Lead Backend Engineer" date="2014 - 2015" link="/droppie">
      <slot>
        <p>In 2015 I joined my first startup: Droppie. Droppie is a location-based social network, where people could "drop" images and other people could pick them up when they walked over them. I was the sole Backend engineer on the project (Python Flask on Google App Engine) and it was my first experience designing something that had to scale to tens of thousands of users. <a href="/droppie">More details here</a>.</p>
      </slot>
    </Employment>

  </div></div>
  <div class="section"><div class="sectionbind"><span id="techstack"></span>
    <h1>Tech Stack</h1>
      <div style="display:flex; justify-content: center; flex-wrap: wrap; gap: 20px">

        <Skill name="Python" image="/img/skill/python.svg"><slot>
          <b>Expert Level</b>. Python will always have a special place in my heart. I have built entire companies on it and will gladly continue to do so. :) Being a language that allows everything from bashing together a prototype in 2 days all the way to a production-grade system <i>and</i> having so much support for AI makes it my top choice for tackling most problems.
        </slot></Skill>
        <Skill name="AWS" image="/img/skill/aws.jpeg"><slot>
          <b>Intermediate Level</b>. Designed and wrote entire clouds using the AWS CDK. Made use of Fargate, ECS (becasue GPUs), RDS, S3, SQS, SNS, Lambda, CloudFront, SageMaker and a whole bunch more. Wrote guides for entire teams on CDK stacks and constructs. Given how vast the AWS ecosystem is though, I still think this is just scratching its surface.
        </slot></Skill>
        <Skill name="Java" image="/img/skill/java.png"><slot>
          <b>Advanced Level</b>. The largest project I worked on in Java was a complete solution that played the RoboCup Small-Size League. Everything from image processing for detecting robots, through an AI strategy system, all the way to a radio controller module. Basically made a Java-controlled football-playing robot.
        </slot></Skill>
        <Skill name="VueJS" image="/img/skill/vue.png"><slot>
          <b>Intermediate Level</b>. In all honesty, frontend is not my forte, but for the moments when I <i>do</i> need to create some user interface I reach for Vue. We've been through some projects together, but I never had the opportunity to dive into it fully.
        </slot></Skill>
        <Skill name="Git" image="/img/skill/git.png"><slot>
          <b>Advanced Level</b>. Not just daily usage but also teaching. Most people have a minimal understanding of Git and don't have much confidence in it. Giving your team confidence in Git goes a long way.
        </slot></Skill>
        <Skill name="Ethereum" image="/img/skill/ethereum.png"><slot>
          <b>Professional Level</b>. I have a strong understanding of the Ethereum Virtual Machine, wrote smart contracts in Solidity and wrote some directly in EVM bytecode - there were things I needed to do that Solidity didn't support and I'd do anything for a competitive advantage. :)
        </slot></Skill>
        <Skill name="GitHub" image="/img/skill/github.png"><slot>
          <b>Advanced Level</b>. GitHub has a whole suite of products to offer and I have dabbled in a lot of them. Designed and implemented an entire self-contained development environment on GitHub covering everything from development in codespaces to deployment into the cloud. The use case for this setup was very niche and not necessary for most projects. This project though... was quite something.
        </slot></Skill>
        <Skill name="OpenCL" image="/img/skill/opencl.png"><slot>
          <b>Beginner Level</b>. Let's face it, who <i>didn't</i> want to try writing a custom GPU kernel at some point? Learned OpenCL as part of my Master's project, I'll even give you a <a href="https://bitbucket.org/SimonRovder/t628nn/src/master/">peek at the code</a> if you want. Just keep in mind I wrote it 7 years ago... and have not reviewed it since.
        </slot></Skill>
      </div>
  </div></div>
  <div class="section"><div class="sectionbind"><span id="personal"></span>
    <h1>Personal Projects</h1>
    <div style="display: flex; flex-wrap: wrap; justify-content: center; gap: 20px; align-items: start;">
      <Personal image="/img/personal/robots.jpg" name="Robocup Small Size League" link="/robocup" alt="Robots Fred and Null">
        <template v-slot:text>As a part of the System Design Practical at The University of Edinburgh I made a football-playing robot. Being a combination of hardware, firmware, computer vision, and AI strategy, this course truly covered it all and was definitely one of the highlights of my uni time. <a href="/robocup">More details here</a>.</template>
      </Personal>
      <Personal image="/img/personal/maze.jpg" name="Maze Generation" link="/mazes" alt="Closeup of the maze structure">
        <template v-slot:text>
          This is exactly the kind of thing that happens when a bored programmer is stuck at home because of the Scottish weather. If you were ever wondering what the world-record largest maze ever generated is, you may now stop wondering and check out the <a href="/mazes">story of its creation here</a>. :)
        </template>
      </Personal>
      <Personal image="/img/personal/ETHGG.png" name="Ethereum Gatling Gun" link="https://ethgg.rovder.com/" alt="Eth GG Logo">
        <template v-slot:text>
          This project started off as a joke/dare, when my colleagues said it's impossible, so naturally I had to do it... The Ethereum Gatling Gun is a contract wallet that enables its owner to send arbitrary amounts of arbitrary transactions on the Ethereum blockchain. Basically, a rapid-fire wallet. It allows for sending transactions hundreds of times faster at a fractional price (gas savings). Check out the <a href="https://ethgg.rovder.com">Ethereum Gatling Gun operation page</a>, the <a href="https://medium.com/@simon.rovder/ethereum-gatling-gun-the-fastest-wallet-out-there-e05269f38da8">Medium article</a> as well as the <a href="https://www.youtube.com/watch?v=yY2QgMx_gh0">demo video</a>.
        </template>
      </Personal>
      <Personal image="/img/personal/nn.png" name="Neural Networks Demystified" link="https://inf.rovder.com/inf2b/nn.html" alt="Preview of the Maths-to-Code idea">
        <template v-slot:text>
          In 2018 I was mentoring students of the Machine Learning course at the University of Edinburgh. The lecturers love describing the mathematics behind neural networks and tend to gloss over what the fancy maths looks like in code. This frustrates engineers, so I decided to write a side-by-side maths-to-code implementation of a simple neural network and give it to my students. I think it demystified it sufficiently.
        </template>
      </Personal>
    </div>
    </div></div>
    <div class="section"><div class="sectionbind"><span id="talks"></span>
      <h1>Talks & Appearances</h1>

      <h2>Paradigm Shift in Infarction Detection</h2>
      <p>This 7-minute lightning talk covers the flagship AI model of Powerful Medical: the <b style="white-space: nowrap;">Queen of Hearts model</b>. It can detect infarctions on an ECG earlier than any doctor and it is driving a true shift in the paradigm of cardiac patient treatment. I gave this talk on an Accenture event in Bratislava and it was one of the most challenging talks to prepare due to the strict time limit for explaining such a complex topic. But I think it worked. :)</p>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/ml0Hx8hXdTQ?si=cZNuJszNU1MUom2-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <hr/>
      <div style="height: 20px"></div>
      <h2>AI in Healthcare</h2>
      <p>In 2023 I was invited to an interview on a Slovak TV channel to talk about the advancements in AI being driven by Powerful Medical. We cover the PMcardio app, its use cases, as well as the progress we are making at Powerful Medical towards going beyond what expert cardiologists can do.</p>
      <iframe xmlns="http://www.w3.org/1999/xhtml" width="640" height="360" scrolling="no" frameborder="0" allowfullscreen="" allow="autoplay; fullscreen" src="https://media.joj.sk/embed/1FSRhw5MQ0n?autoplay=0" title="AI in Healthcare"></iframe>
  </div></div>
  <div class="section"><div class="sectionbind"><span id="publications"></span>
    <h1>Publications & Patents</h1>
    <div class="publication-patent">Patent (2022) - <a href="https://patentscope.wipo.int/search/en/detail.jsf?docId=WO2022184740&_cid=P20-LP5PF5-87019-1">METHODS FOR GENERATING AN IMAGE OF A GRAPHICAL REPRESENTATION</a></div>
    <p></p>
    <div class="publication-patent">Publication (2019) - <a href="https://www.researchgate.net/publication/330638173_Optimising_Convolutional_Neural_Networks_Inference_on_Low-Powered_GPUs">Optimising Convolutional Neural Networks Inference on Low-Powered GPUs</a></div>
  </div></div>
  <div class="section"><div class="sectionbind"><span id="regulatory"></span>
    <h1>Regulatory Experience</h1>
    <p>
      Since Powerful Medical is a Medical Device Manufacturer as understood by the European Medical Device Regulation (MDR), we have to conform to a wide variety of standards. As the CTO I get to work with a majority of them to varying levels of involvement, and have:
    </p>
    <ul>
      <li>Onboarded the company onto a Quality Management System (ISO 13485)</li>
      <li>Implemented Medical Device Software Development Standards (ISOs 62304 and 82304)</li>
      <li>Implemented standards for deploying Medical Devices in Production (ISO 80001)</li>
      <li>Participated in Medical Device Risk Management (ISO 14971)</li>
    </ul>
    <p>And of course, now that we're certified, I get to regularly face off with auditors from TUV SUD who come check on us yearly. :-)</p>
    <p>
      Regulation tends to be seen as a sort of boogeyman in the startup world, but over the last couple of years I have learned that it really isn't that scary. In all honesty, most standards simply formalize and explicitly require things I personally would consider good practice and common sense anyway. 
    </p>
  </div></div>
</div>
</template>

<script>
// @ is an alias to /src
import Octagon from '@/components/Octagon.vue'
import Employment from '@/components/Employment.vue'
import Skill from '@/components/Skill.vue'
import Personal from '@/components/Personal.vue'
import Intro from '@/components/Intro.vue'


export default {
  name: 'Index',
  components: {
    Intro,
    Octagon,
    Employment,
    Skill,
    Personal,
  },
  mounted() {
    document.title = "Simon Rovder";
  }
}
</script>
<style>



.intro-text h1 {
  margin-bottom: 0;
}

.content-pane div {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

.section {
  width: 100%;
  min-height: 50px;
  clear: both;
}

.section h1 {
  font-weight: bold;
  font-size: 2.5em;
  text-align: center;
  margin: 0 0 40px 0;
}

.sectionbind {
  width: clamp(min(400px, 85%), 70vw, min(95%, 30cm));
  padding: 1cm 0 1cm 0;
  margin: 0 auto 0 auto;
  display: flex;
  flex-direction: column;
  /* background-color: red; */
}


.section-heading {
  font-size: 2em;
}

#sections .section:nth-child(2n+1) {
  background-color: var(--theme-bright-background);
}

.talk {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.publication-patent {
  font-size: 1.2em;
  text-align: center;
}

iframe {
  max-width: 100%;
}

</style>
