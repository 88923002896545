<template>
    <div class="section"><div class="robots-page">
    <h1>Maze Generation</h1>
    <p>Winter 2015. I'm sitting at home, the winds and rains are pushing against the poor window in my room. The sun
    is but a faded memory of a different life and I'm praying to whatever higher power would hear me, that
        the nail I used to hammer my window back into place
    earlier that day will hold. In other words, a typical Scottish Wednesday.</p>

    <P>Anyway, I'm browsing random videos on Youtube, when I stumble upon a rather curious video that
        mentioned the "Largest Maze on the Internet". It was this little snippet right here:
    </P>
    <iframe width="230" height="160" start="71" end="102" src="https://www.youtube.com/embed/t2ZWf-DeexY?start=71&end=102" frameborder="0" allowfullscreen></iframe>

    <P>And just like that, boredom was gone - 10 000 by 10 000 pixel maze? Surely I could do better than that... right..?
    </P>

    <P>So I quickly whipped out PyCharm and started coding. Snatching a bitmap writing library from the internet was
    the easiest thing and within a couple of minutes I had a DFS recursive maze carving algorithm. Not much, not very effective,
        but I wanted to see some result to bounce off of and here it was:</P>
    <IMG src="/img/mazes/30.BMP"/>
    <P>Yaaaaay! I have a maze!</P>
    <P>I know what you're thinking... You're thinking "That's the tiniest maze I have ever seen." And yes, you're right, but its a start.</P>
    <P>Now to make it larger. The stack is generally very unhappy with this kind of approach, so I decided to implement my own "stack".
    Basically storing the values a function should be called with recursively, and calling it only once the initial function exits.
        Not too shabby, the stack is happy and here's what I got:</P>
    <IMG src="/img/mazes/500.BMP"/>
    <P>Here's the thing with area... It scales quadratically with the length of the sides. It took my inefficient algorithm
    about 5 seconds to generate this 500 by 500 maze and unless my math is wrong, making a maze of 10 000 by 10 000 pixels would
    take about a half hour. And that is the <I>current</I> record holder, which I'd have to beat. In other words, a new, better algorithm was needed.</P>
    <P>Picking a new algorithm was a very straight-forward task because of one key aspect. Parallelism. There are a variety of maze generation algorithms,
        but only one really stands out with respect to parallelism and it is the <A target="_blank" href="https://en.wikipedia.org/wiki/Maze_generation_algorithm#Recursive_division_method">Recursive Division Method</A>.
        The general idea is, you start off with an empty "room", split it in half with a wall,
        make a hole in the wall and repeat this on the two newly created rooms.
    </P>
    <P>I think Wikipedia has a very nice GIF explaining it:</P>
    <IMG src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/90/Recursive_maze.gif/220px-Recursive_maze.gif"/>
    <P>OK, looks pretty straight forward, let's give it a shot!</P>
    <P><B>*coding*</B></P>
    <P><B>*coding*</B></P>
    <P><B>*lunch*</B></P>
    <P><B>*coding*</B></P>
    <P>At this point, I ran the code and a very crappy looking maze came out of it (I forgot to save this one). I got misled by how simple Wikipedia
    made it look and realised I had to add some order to the randomness. Due to the nature of the algorithm, one needs to be careful
    to make sure the walls never block out a hole in the wall. Turns out following two simple rules solves this:
    </P>
    <UL>
        <LI>Only build walls along an <B><I>even</I></B> coordinate</LI>
        <LI>Only put the holes on <B><I>odd</I></B> coordinates</LI>
    </UL>
    <P>Never blindly trust Wikipedia... OK, never mind, the fix has been added, code has been run and voila:</P>
    <IMG src="/img/mazes/small.jpg"/>
    <P>Looks pretty good! And seems to be very fast, I'm happy. OK, it's time to do the thing where I beat the record holder! Let's see, what was the record again?
    I look up the website mentioned in the video and... the video was wrong. The record is no longer 10 000 by 10 000, it is now 32767 by 32767.</P>
    <P>OK, never fear, I have faith in my algorithm, let's make a 40 000 by 40 000 maze and beat the record!</P>
    <P>Several minutes later, the algorithm is done, I open the file and snap. "File is Invalid". All the programs I used seemed to uniformly agree that
    the file is invalid. But why? Well... It turns out there is a reason the record holder is only as large as it is. The size
    limit on a Bitmap file is 32767 by 32767 pixels.</P>
    <P>Ehm... Well this is awkward...</P>
    <P>Now what?</P>
    <P>It's not like I was going to give up here. I was very determined on beating the world record. So I devised a plan. It was time for some good
    old fashioned Divide-And-Conquer.</P>
    <P>The plan was simple: Preprocess the required dimensions of the maze and split it into several smaller "tiles". Store the
    describing parameters of these tiles in a data structure and then generate all these tiles separately in parallel. Once all the
    tiles have been generated, create a .html file which includes all the tiles in a recursive table structure generated to
    "stitch" the tiles back together.</P>
    <P>Or simply put: Generate the maze by parts.</P>
    <P>Sounds simple enough, right? Well, it turns out, it really is simple. Due to the recursive nature of the algorithm and the two
        rules about placing walls and holes, it is quite easy to split the maze into smaller tiles and have them be independent
        of one another. This is the kind of result I got:</P>
    <IMG src="/img/mazes/split.bmp"/>
    <P>This was Victory. The limits of the Bitmap file were handled, the stitching worked, I wrote my own Bitmap file generating code
    (because nowhere on the internet could I find a way to create <i>monochromatic</i> bitmaps in Python) and am ready to do this.</P>
    <P>I ran the code and it was a success. A 40 000 by 40 000 maze has been generated. You can find it below, even though your browser might not be
    very happy with the amount of memory those images require and might leave some of them out (rest assured though, they <i>are</i> all there).</P>
    <P><A href="http://mazes.rovder.com/40000/maze.html" target="_blank">The record breaking 40 000 by 40 000 pixel maze</A></P>
    <P>See, this is where most people would call it a day and decide they have something better to do. Not me though. I wanted to see how
    far I could go, I wanted to take it to it's naturally ridiculous conclusion, so I rented out a 16 core Compute Engine instance on the Google
    Cloud, mounted 150 Gigabytes of memory to it, put the code there and ran it to generate a 1 000 000 by 1 000 000 pixel maze.</P>
    <P>32 hours later, there it was. 125 Gigabytes of monochromatic Bitmap files. I downloaded this monstrosity of a maze and have it taking up
        a significant chunk of one of my disks.</P>
    <P>It was an interesting project, I had fun and potentially broke some world records.</P>
    <P>...</P>
    <P>Yaaaaay! : )</P>
    </div></div>
</template>
<script>
export default {
  name: 'Mazes',
  mounted() {
    document.title = "Maze Generation";
  }
}
</script>
<style>

.robots-page {
  width: clamp(min(400px, 85%), 70vw, min(95%, 30cm));
  padding-top: 3vmin;
  padding-bottom: 3vmin;
  margin: 0 auto 0 auto;
}

</style>