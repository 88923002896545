import { createRouter, createWebHistory } from 'vue-router'
import Droppie from '../views/Droppie.vue'
import Index from '../views/Index.vue'
import Robots from '../views/Robots.vue'
import Mazes from '../views/Mazes.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index,
    meta: { title: 'Simon Rovder' },
  },
  {
    path: '/droppie',
    name: 'droppie',
    component: Droppie
  },
  {
    path: '/robocup',
    name: 'robocup',
    component: Robots,
  },
  {
    path: '/mazes',
    name: 'mazes',
    component: Mazes,
  },
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
